import React from "react";
import { Helmet } from "react-helmet";
import Navigation from "../../components/Navigation";
import { InlineWidget } from "react-calendly";
import "./Tour.css";

export default function Tour() {
  return (
    <div className="Tour">
      <Helmet>
        <title>
          Schedule a Tour - Visit Sixth & Main Event Center in Robstown, TX
        </title>
        <meta
          name="description"
          content="Plan a visit to Sixth & Main Event Center in Robstown, TX to explore our venue rental space facilities and discuss your event requirements with our staff. Book your tour now."
        />
        <link
          rel="icon"
          href="/favicon.ico"
          sizes="any"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link
          rel="manifest"
          href="/site.webmanifest"
        />
        <link
          rel="mask-icon"
          href="/safari-pinned-tab.svg"
          color="#000000"
        />
        <meta
          name="msapplication-TileColor"
          content="#000000"
        ></meta>
      </Helmet>
      <div className="hero">
        <Navigation />
        <div>
          <h1 className="text-white text-center px-2">
            Venue tours available,
            <br />
            book yours today!
          </h1>
        </div>
      </div>
      <div
        name="tour"
        className="Tour container d-block m-auto py-5"
      >
        <div className="container my-5">
          <div className="row">
            <div
              className="col-sm-6 my-sm-3 py-4 bg-light"
              id="tour"
            >
              <h2 className="mb-4">
                Schedule a Tour{" "}
                <span className="d-none d-sm-inline-block">→</span>
              </h2>
              <p>
                As you tour the venue, you'll notice the versatility of the
                space. The open floor plan allows for easy configuration to
                accommodate your specific event needs.
              </p>

              <iframe
                className="img-fluid mb-3 d-block mx-auto"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/6YAl0zQG7Mc?si=ocSwiPdD1ZfXHruO"
                title="YouTube video player"
                alt="Video tour of Robstown, Texas venue, Sixth & Main Event Center"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen="true"
              ></iframe>

              <p>
                We invite you to come and see for yourself the potential our
                event venue holds for your next special occasion.
              </p>
              <h4 className="fw-medium">
                Schedule a tour now to start planning your unforgettable event!
              </h4>
            </div>
            <div className="col-sm-6 calendly-inline-widget m-0 p-3 pb-0 my-sm-auto">
              <InlineWidget
                url="https://calendly.com/sixthandmain/venue-tour?hide_event_type_details=1&hide_gdpr_banner=1"
                pageSettings={{
                  primaryColor: "000",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
