import React from "react";
import { Helmet } from "react-helmet";
import Navigation from "../../components/Navigation";
import { InlineWidget } from "react-calendly";
import "./Reservation.css";

export default function Reservation() {
  return (
    <div
      className="Reservation"
      alt="Sixth & Main Event Center is a venue reservable for several types of events included baby showers, bridal showers, birthdays, and more!"
    >
      <Helmet>
        <title>
          Reserve Now - Book Your Event at Sixth & Main Event Center
        </title>
        <meta
          name="description"
          content="Ready to host your event at Sixth & Main Event Center? Reserve your date and secure our premier venue rental space for an unforgettable occasion. Book now."
        />
        <link
          rel="icon"
          href="/favicon.ico"
          sizes="any"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link
          rel="manifest"
          href="/site.webmanifest"
        />
        <link
          rel="mask-icon"
          href="/safari-pinned-tab.svg"
          color="#000000"
        />
        <meta
          name="msapplication-TileColor"
          content="#000000"
        ></meta>
      </Helmet>
      <div className="hero">
        <Navigation />
        <div>
          <h1 className="text-white px-4 px-sm-5 mx-lg-5">
            Reserve your venue rental now, our packages are cut to size
          </h1>
        </div>
      </div>

      <div
        name="reservation"
        className="Reservation"
      >
        <p className="text-center mt-5 container">
          Thank you for your interest in hosting your event at Sixth & Main
          Event Center!{" "}
        </p>
        <p className="text-center container">
          Please review the following{" "}
          <span className="fw-medium">Guidelines & Regulations</span> and
          calendar availability to complete your reservation.
        </p>

        <div>
          <div className="row ms-lg-5 mx-auto">
            <div
              className="col-sm-5 py-3 py-lg-5 my-3 bg-light mx-auto"
              id="regulations"
            >
              <h2 className="text-info-emphasis mb-3">
                Guidelines & Regulations
              </h2>
              <h6 className="fw-medium">VENUE RESERVATION (6hrs)</h6>
              <p>
                Reservation includes 1 hr for setup, 4 hrs for the event, and 1
                hr for teardown. Events surpassing the allotted time slot are
                subject to additional fees.{" "}
              </p>
              <h6 className="fw-medium mt-4">PACKAGE PRICING</h6>
              <ol>
                <li>
                  <span className="fw-medium">Asscher Package - $500:</span>{" "}
                  includes seating for up to 60 people and an entry gift table &
                  dessert table
                </li>
                <li>
                  <span className="fw-medium">Oval Package - $600: </span>{" "}
                  Asscher pkg + table covers
                </li>
                <li>
                  <span className="fw-medium">Emerald Package - $625: </span>{" "}
                  Oval pkg + table runners
                </li>
                <li>
                  <span className="fw-medium">Heart Package - $750: </span>
                  Emerald pkg + table centerpieces
                </li>
                <li>
                  <span className="fw-medium">Radiant Package - $800: </span>
                  Heart pkg + entry table with centerpiece
                </li>
                <li>
                  <span className="fw-medium">Pear Package - $875: </span>
                  Radiant pkg + matching golden arches
                </li>
                <li>
                  <span className="fw-medium">Marquise Package - $950: </span>
                  Pear pkg + 4 matching outside cocktail tables
                </li>
                <li>
                  <span className="fw-medium">Princess Package - $1,275: </span>{" "}
                  the "total package" includes all offerings + a balloon
                  backdrop
                </li>
                <li>
                  <span className="fw-medium">Graduation Package - $625: </span>{" "}
                  includes seating for 60, entry gift table & dessert table,
                  table covers, table runners, table centerpieces, and entry
                  table centerpiece.
                </li>
              </ol>
              <h6 className="fw-medium mt-4">REFUNDABLE DEPOSIT</h6>
              <p>
                10% refundable deposit is returned if venue is cleaned &
                belongings removed from the property.
              </p>
              <h6 className="fw-medium mt-4">PAYMENT PLANS</h6>
              <p>
                Payment plans are available with half down and must be paid in
                full 6 weeks prior to event.
              </p>
              <h6 className="fw-medium mt-4">LOCATION</h6>
              <p>No pets allowed indoors.</p>
              <h6 className="fw-medium mt-4">CAPACITY</h6>
              <p className="pb-sm-5"> Max occupancy of 60 people.</p>
            </div>
            <div className="col-sm-7 mt-0 mt-sm-3 mx-auto calendly-inline-widget">
              <h2 className="fs-1 d-block text-center pt-3 pb-0 mt-5 mt-sm-0 mb-0">
                Availability
              </h2>
              <InlineWidget
                url="https://calendly.com/sixthandmain/venue-reservation?&hide_gdpr_banner=1"
                pageSettings={{
                  primaryColor: "000",
                  hideEventTypeDetails: true,
                }}
                styles={{ height: "700px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
