import { CloudinaryImage } from "@cloudinary/url-gen";
import { AdvancedImage, lazyload } from "@cloudinary/react";
import { fill } from "@cloudinary/url-gen/actions/resize";
import { format } from "@cloudinary/url-gen/actions/delivery";

const ImageCloudinary = ({ publicId }) => {
  // Create a Cloudinary instance and set your cloud name.
  const myImg = new CloudinaryImage(publicId, { cloudName: "dkjabfaoy" });

  // Apply transformations before rendering: Pre-transform the image
  // Resize the image to 800 x 800 pixels using the `fill` crop mode.
  myImg.resize(fill().width(800).height(800)).delivery(format("auto")); // Example format conversion

  // Render the image in a React component.
  return (
    <AdvancedImage
      style={{
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "5px",
      }}
      cldImg={myImg}
      plugins={[lazyload()]}
    />
  );
};

export default ImageCloudinary;
