import { Helmet } from "react-helmet";
import "./Contact.css";
import Navigation from "../../components/Navigation";

const Contact = () => {
  return (
    <div
      className="Contact"
      id="contact-container"
    >
      <Helmet>
        <title>Contact Us - Get in Touch with Sixth & Main Event Center</title>
        <meta
          name="description"
          content="Reach out to Sixth & Main Event Center for inquiries, bookings, or to schedule a tour. Our team is ready to assist you with your event needs."
        />
        <link
          rel="icon"
          href="/favicon.ico"
          sizes="any"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link
          rel="manifest"
          href="/site.webmanifest"
        />
        <link
          rel="mask-icon"
          href="/safari-pinned-tab.svg"
          color="#000000"
        />
        <meta
          name="msapplication-TileColor"
          content="#000000"
        ></meta>
      </Helmet>
      <div className="hero">
        <Navigation />
        <div>
          <h1 className="text-white text-center px-2">
            Questions?
            <span className="d-block text-decoration-underline link-underline-danger link-offset-1">
              We're here to help.
            </span>
          </h1>
        </div>
      </div>

      <div
        className="Contact container h-auto"
        name="contact"
        id="content-wrap"
      >
        <div className="row">
          <div className="col-12 col-sm-6 pt-5">
            <div className="text-start">
              <address>
                <div className="mb-md-4">
                  <a
                    href="mailto:info@sixthandmaineventcenter.com"
                    className="btn btn-primary bg-dark text-uppercase mb-3 d-block m-auto"
                  >
                    Message Us
                  </a>
                  <p
                    className="text-black fw-bold mb-0"
                    id="icon"
                  >
                    Phone
                  </p>
                  <p>
                    <span className="phone">
                      <a
                        href="tel:+13617656189"
                        id="telephone"
                        className="text-black"
                      >
                        361-765-6189
                      </a>
                    </span>
                  </p>
                </div>
                <div className="mb-md-4">
                  <p
                    className="text-black fw-bold mb-0"
                    id="icon"
                  >
                    Email
                  </p>
                  <p>
                    <a
                      href="mailto:info@sixthandmaineventcenter.com"
                      className="link-secondary link-opacity-25-hover text-black"
                    >
                      info@sixthandmaineventcenter.com
                    </a>
                  </p>

                  <p className="text-black fw-bold mb-0">Address</p>
                  <p>
                    <a
                      href="https://maps.app.goo.gl/WrxiAMR36ye59tuV8"
                      target="_blank"
                      rel="noreferrer"
                      className="text-black"
                      id="address"
                    >
                      101 N 6th St, Unit A Robstown, Texas 78380​
                    </a>
                  </p>
                </div>
              </address>
              <div className="mb-md-4">
                <p
                  className="text-black fw-bold mb-0"
                  id="icon"
                >
                  Hours
                </p>
                <ul className="ps-0">
                  <li>Monday: 8AM-8PM</li>
                  <li>Tuesday: 8AM-8PM</li>
                  <li>Wednesday: 8AM-8PM</li>
                  <li>Thursday: 8AM-8PM</li>
                  <li>Friday: 8AM-8PM</li>
                  <li>Saturday: 10AM-8PM</li>
                  <li>Sunday: 10AM-8PM</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div
              className=""
              id="contact-options"
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d727.19802050513!2d-97.66222709337386!3d27.78950786363994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86687db053a405f9%3A0x6afa62d440481b8d!2sSixth%20%26%20Main%20Event%20Center!5e0!3m2!1sen!2sus!4v1700076225668!5m2!1sen!2sus"
                width="300"
                height="600"
                allowFullScreen="true"
                className="w-100 my-auto py-5"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Map and Directions to Sixth & Main Event Center Venue"
                alt="Embedded map of the venue Sixth & Main Event Center in Robstown, Texas"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
