import React from "react";
import { Helmet } from "react-helmet";
import Navigation from "../../components/Navigation";
import Event from "../../assets/sixth-and-main-venue-event.jpeg";
import Private from "../../assets/private.png";
import Corporate from "../../assets/corporate.png";
import Special from "../../assets/birthday.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import "./Home.css";

export default function Home() {
  return (
    <div className="Home">
      <Helmet>
        <title>
          Sixth & Main Event Center - Premier Venue for Events in Robstown, TX
        </title>
        <meta
          name="description"
          content="Discover Sixth & Main Event Center, the perfect venue for private events, corporate events, and special occasions in Robstown, TX. Reserve your venue rental space now!"
        />
        <link
          rel="icon"
          href="/favicon.ico"
          sizes="any"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link
          rel="manifest"
          href="/site.webmanifest"
        />
        <link
          rel="mask-icon"
          href="/safari-pinned-tab.svg"
          color="#000000"
        />
        <meta
          name="msapplication-TileColor"
          content="#000000"
        ></meta>
      </Helmet>
      <div className="hero">
        <Navigation />
        <div className="container">
          <div className="row">
            <div
              name="home"
              className="col-12"
            >
              <h1 className="text-center px-2">
                Host your dream event <br />
                at our boutique venue
              </h1>

              <a
                href="/reservation"
                className="btn btn-primary d-block mx-auto px-1 mt-4 py-md-3 text-uppercase"
                role="button"
                id="book-now"
              >
                <FontAwesomeIcon
                  icon={icon({
                    name: "calendar-days",
                    family: "duotone",
                    style: "solid",
                  })}
                  className="mx-2"
                />
                Book Now
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="main">
        <div className="container section">
          <div className="primary row px-lg-5">
            <div className="col-12 col-lg-6 my-auto px-lg-5">
              <h3 className="mb-3 text-center text-lg-start">
                Private Event Space in Historic Downtown Robstown
              </h3>
              <p className="text-center text-lg-start">
                Welcome to Sixth & Main Event Center, your premier destination
                for intimate gatherings & celebrations in the heart of Robstown,
                Texas.
              </p>

              <p className="text-center text-lg-start">
                Our charming venue, nestled at the corner of 6th & Main Avenue,
                offers a versatile space that can accommodate up to 60 guests
                for a variety of events.
              </p>

              <a
                href="/reservation"
                className="btn btn-primary text-uppercase"
                role="button"
                id="availability-btn"
              >
                <FontAwesomeIcon
                  icon={icon({
                    name: "calendar-days",
                    family: "duotone",
                    style: "solid",
                  })}
                  className="mx-2"
                />
                View Availability
              </a>
            </div>

            <div className="col-12 col-lg-6 px-5 d-none d-lg-block">
              <img
                src={Event}
                className="rounded img-fluid py-5 my-5"
                alt="Exterior building that houses Sixth & Main Event Center"
              />
            </div>
          </div>
        </div>

        <div
          className="container section p-4"
          id="banner-home"
          style={{ maxWidth: "2000px" }}
        >
          <div className="text-center d-flex align-items-center justify-content-center px-2">
            <p className="text-center text-white fw-medium p-4 mx-sm-5">
              Our intimate venue is perfect for creating memorable experiences
              and connecting with your guests.
            </p>
          </div>
        </div>

        <div className="secondary section">
          <div className="text-center">
            <h2>Event Space</h2>
            <h3>Configurable for any Event Type</h3>
            <p className="text-center px-sm-3 mx-1 mt-3 mb-4">
              Whether you're planning a baby shower, a corporate holiday party,
              or any special occasion in between, Sixth & Main Event Center
              provides a customizable and elegant setting to bring your event to
              life.
            </p>
          </div>

          <div className="container px-0">
            <div
              className="row"
              id="card-row"
            >
              <div className="col-12 col-sm-4">
                <div className="card px-2 pb-sm-3 shadow">
                  <div className="card-body">
                    <div className="card-header">
                      <img
                        src={Private}
                        alt="People with balloons"
                        className="img-fluid my-auto"
                      />
                    </div>
                    <div className="card-body-content">
                      <h4>Private Events</h4>
                      <ul className="ps-4">
                        <li>Baby Showers</li>
                        <li>Bridal Showers</li>
                        <li>Graduation Parties</li>
                      </ul>
                    </div>

                    <a
                      href="/reservation"
                      className="btn btn-primary py-2 mb-2 text-uppercase"
                    >
                      Book Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-4">
                <div className="card px-2 pb-sm-3 shadow">
                  <div className="card-header">
                    <img
                      src={Corporate}
                      alt="Corporate leader giving presentation"
                      className="img-fluid my-auto"
                    />
                  </div>

                  <div className="card-body">
                    <div className="card-body-content">
                      <h4>Corporate Events</h4>
                      <ul className="ps-4">
                        <li>Staff Appreciation</li>
                        <li>Seminars</li>
                        <li>Trainings</li>
                      </ul>
                    </div>

                    <a
                      href="/reservation"
                      className="btn btn-primary py-2 mb-2 text-uppercase"
                    >
                      Book Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-4">
                <div className="card px-2 pb-sm-3 shadow">
                  <div className="card-header">
                    <img
                      src={Special}
                      alt="Two friends sitting down at a table with a birthday cake"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body">
                    <div className="card-body-content">
                      <h4>Special Events</h4>
                      <ul className="ps-4">
                        <li>Birthdays</li>
                        <li>Milestones</li>
                        <li>Holiday Parties</li>
                      </ul>
                    </div>

                    <a
                      href="/reservation"
                      className="btn btn-primary py-2 mb-2 text-uppercase"
                    >
                      Book Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="vision section container">
          <div className="row">
            <div className="col-12">
              <h5 className="text-white text-center">
                Bring your vision to life
              </h5>
              <a
                href="/about"
                className="btn btn-primary rounded my-3 py-3 text-uppercase text-nowrap"
                role="button"
                id="learn-more-btn-bottom"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
