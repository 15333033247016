import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

const Footer = () => {
  return (
    <div className="Footer container">
      <div className="d-flex justify-content-between mx-sm-2">
        <div class="row mx-4 px-sm-2 d-flex justify-content-between">
          <div className="col-12 col-sm-4">
            <h6 className="mb-2 text-center text-sm-start">About</h6>
            <p className="text-center text-sm-start">
              Sixth & Main Event Center is located in the heart of downtown
              Robstown, TX. The venue is a small and elegant space configurable
              for any type of event.
            </p>
          </div>

          <div className="col-12 col-sm-4">
            <h6 className="mb-2 text-center text-sm-start">Services</h6>
            <p className="text-center text-sm-start">
              Private Parties <br />
              Corporate Events <br />
              Special Events
            </p>
          </div>

          <div className="col-12 col-sm-4">
            <h6 className="mb-2 text-center text-sm-start">Contact</h6>
            <p className="text-center text-sm-start">
              <address>
                <a
                  href="https://maps.app.goo.gl/WrxiAMR36ye59tuV8"
                  target="_blank"
                  rel="noreferrer"
                  id="address"
                >
                  101 N 6th St, Unit A
                  <br />
                  Robstown, Texas 78380
                </a>
                <br />
                <a
                  href="mailto:info@sixthandmaineventcenter.com"
                  id="footer-link"
                >
                  info@sixthandmaineventcenter.com
                </a>
                <br />
                <a
                  href="tel:+13617679162"
                  id="telephone"
                >
                  Tel: 361-765-6189
                </a>
                <br />
              </address>
            </p>
          </div>
        </div>
      </div>

      <hr className="w-25 d-block m-auto my-3" />

      <div
        className="text-center"
        id="socials"
      >
        <a
          href="https://www.facebook.com/people/Sixth-Main-Event-Center/61553590640988/"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon
            icon={icon({
              name: "square-facebook",
              family: "classic",
              style: "brands",
            })}
            className="me-3 fs-4"
          />
        </a>

        <a
          href="https://www.instagram.com/6thandmaineventcenter/"
          target="_blank"
          rel="noreferrer"
          id="icon"
        >
          <FontAwesomeIcon
            icon={icon({
              name: "instagram",
              family: "classic",
              style: "brands",
            })}
            className="me-3 fs-4"
          />
        </a>
        <a
          href="https://maps.app.goo.gl/2BXfBRccVN2iXeer9"
          target="_blank"
          rel="noreferrer"
          id="icon"
        >
          <FontAwesomeIcon
            icon={icon({
              name: "location-dot",
              family: "classic",
              style: "solid",
            })}
            className="me-3 fs-4"
          />
        </a>
        <a
          href="mailto:info@sixthandmaineventcenter.com"
          target="_blank"
          rel="noreferrer"
          id="icon"
        >
          <FontAwesomeIcon
            icon={icon({
              name: "envelope",
              family: "classic",
              style: "solid",
            })}
            className="fs-4"
          />
        </a>
      </div>

      <div class="mt-3 pb-0 text-black text-center">
        <p>Sixth & Main Event Center &copy; {new Date().getFullYear()}.</p>
      </div>
    </div>
  );
};

export default Footer;
