import React from "react";
import { Helmet } from "react-helmet";
import Navigation from "../../components/Navigation";
import "./About.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone, icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import Building from "../../assets/Sixth & Main Event Center building exterior.jpeg";
import Scent from "../../assets/scent.jpg";
import Decor from "../../assets/baby-shower.jpeg";

export default function About() {
  return (
    <div className="About">
      <Helmet>
        <title>About Sixth & Main Event Center - Learn About Our Venue</title>
        <meta
          name="description"
          content="Discover the history and vision of Sixth & Main Event Center. Learn about our versatile event venue rental space and exceptional services."
        />
        <link
          rel="icon"
          href="/favicon.ico"
          sizes="any"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link
          rel="manifest"
          href="/site.webmanifest"
        />
        <link
          rel="mask-icon"
          href="/safari-pinned-tab.svg"
          color="#000000"
        />
        <meta
          name="msapplication-TileColor"
          content="#000000"
        ></meta>
      </Helmet>

      <div className="hero">
        <Navigation />
        <div className="px-5 px-sm-1">
          <h1 className="text-white">Crafting memories, one event at a time</h1>
        </div>
      </div>

      <div
        name="about"
        className="About py-5 text-center text-sm-start"
      >
        <div>
          <div className="container mb-5">
            <div className="row container">
              <div className="col col-sm-6 my-auto px-0 px-md-3">
                <h3 className="fs-1">About the venue</h3>
                <p className="mb-sm-3 mx-md-2 text-center text-sm-start">
                  Sixth & Main Event Center is conveniently located in the heart
                  of downtown Robstown, TX. The venue rental space is
                  configurable for Private Parties, Corporate Meetings, and
                  more!
                </p>

                <p>Occupancy: 60 people.</p>
              </div>
              <div className="col-none col-sm-6 my-auto p-0 p-lg-5 rounded">
                <img
                  src={Building}
                  alt="Planner with pen and plant decor"
                  className="img-fluid d-none d-sm-block ms-sm-3 rounded"
                  id="vision-illustration"
                />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row container">
              <div className="col-none col-sm-6 my-auto p-0 p-lg-5 rounded">
                <img
                  src={Scent}
                  alt="Planner with pen and plant decor"
                  className="img-fluid d-none d-sm-block mx-auto rounded"
                  id="vision-illustration"
                />
              </div>
              <div className="col col-sm-6 my-auto px-0 px-sm-3">
                <h3 className="fs-1">The "IT" Factor</h3>
                <p className="mb-sm-3">
                  Here at Sixth & Main Event Center, we take pride in everything
                  we do. We work with each client to ensure the detail in the
                  decor exceeds their expectations.
                </p>

                <p>
                  We even offer{" "}
                  <span className="text-decoration-underline">
                    custom scents
                  </span>{" "}
                  to suit your event's theme!
                </p>
              </div>
            </div>
          </div>

          <div className="container">
            <hr />
            <div
              className="py-4"
              id="uses-container"
            >
              <h3 className="text-start ms-3">Ideal Uses</h3>
              <div className="container text-start ms-sm-3 pe-md-5">
                <div
                  className="row"
                  id="venue-uses"
                >
                  <div className="col-6 col-md-4 text-dark">
                    Celebrate
                    <ul className="ms-2">
                      <li>
                        <FontAwesomeIcon
                          icon={icon({
                            name: "baby",
                            family: "duotone",
                            style: "solid",
                          })}
                          className="me-2"
                        />
                        Baby Showers
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon={icon({
                            name: "rings-wedding",
                            family: "duotone",
                            style: "solid",
                          })}
                          className="me-2"
                        />
                        Bridal Showers
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon={icon({
                            name: "graduation-cap",
                            family: "duotone",
                            style: "solid",
                          })}
                          className="me-2"
                        />
                        Graduation Parties
                      </li>
                    </ul>
                  </div>
                  <div className="col-6 col-md-4 text-dark">
                    Gather
                    <ul className="ms-2">
                      <li>
                        <FontAwesomeIcon
                          icon={icon({
                            name: "cake",
                            family: "duotone",
                            style: "solid",
                          })}
                          className="me-2"
                        />
                        Birthdays
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon={icon({
                            name: "award",
                            family: "duotone",
                            style: "solid",
                          })}
                          className="me-2"
                        />
                        Milestones
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon={icon({
                            name: "lights-holiday",
                            family: "duotone",
                            style: "solid",
                          })}
                          className="me-2"
                        />
                        Holiday Events
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 col-md-4 text-dark">
                    Grow
                    <ul className="ms-2">
                      <li>
                        <FontAwesomeIcon
                          icon={duotone("handshake")}
                          className="me-2"
                        />
                        Employee Appreciation
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon={icon({
                            name: "people-group",
                            family: "duotone",
                            style: "solid",
                          })}
                          className="me-2"
                        />
                        Seminars
                      </li>
                      <li>
                        <FontAwesomeIcon
                          icon={icon({
                            name: "presentation-screen",
                            family: "duotone",
                            style: "solid",
                          })}
                          className="me-2"
                        />
                        Trainings
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>

          <div className="bg-black py-5 my-3 my-lg-5 graduation mx-0 mx-sm-3">
            {" "}
            <h3 className="grad-special text-center text-white fs-1 fw-medium text-center text-uppercase">
              🎓 Graduation special: $625 🎓
            </h3>
            <p className="text-center text-white my-4 my-md-5 mx-auto w-75 fs-4">
              Includes seating for 60, entry gift & dessert table, table covers,
              table runners, table centerpieces, and entry table centerpiece.
            </p>
            <button className="btn rounded">
              <a
                className="fs-4 d-block about-btn-avail"
                href="/reservation"
              >
                <FontAwesomeIcon
                  icon={icon({
                    name: "calendar-days",
                    family: "duotone",
                    style: "solid",
                  })}
                  className="mx-2"
                />
                View Availability
              </a>
            </button>
          </div>

          <div className="container pricing text-white py-3 mx-auto d-none d-lg-block">
            <table className="table table-light text-center shadow">
              <thead>
                <tr>
                  <th
                    colspan="9"
                    className="fs-1"
                  >
                    Package Pricing
                  </th>
                </tr>
                <tr>
                  <th
                    scope="col"
                    className="text-start text-uppercase"
                  >
                    What's Included
                  </th>
                  <th scope="col">
                    <span
                      className="text-uppercase text-dark"
                      style={{ fontSize: "12px" }}
                    >
                      Asscher
                    </span>

                    <span className="d-block fs-4">$500</span>
                  </th>
                  <th scope="col">
                    <span
                      className="text-uppercase text-dark"
                      style={{ fontSize: "12px" }}
                    >
                      Oval
                    </span>

                    <span className="d-block fs-4">$600</span>
                  </th>
                  <th scope="col">
                    <span
                      className="text-uppercase text-dark"
                      style={{ fontSize: "12px" }}
                    >
                      Emerald
                    </span>
                    <span className="d-block fs-4">$625</span>
                  </th>
                  <th scope="col">
                    <span
                      className="text-uppercase text-dark"
                      style={{ fontSize: "12px" }}
                    >
                      Heart
                    </span>
                    <span className="d-block fs-4">$750</span>
                  </th>
                  <th scope="col">
                    <span
                      className="text-uppercase text-dark"
                      style={{ fontSize: "12px" }}
                    >
                      Radiant
                    </span>
                    <span className="d-block fs-4">$800</span>
                  </th>
                  <th scope="col">
                    <span
                      className="text-uppercase text-dark"
                      style={{ fontSize: "12px" }}
                    >
                      Pear
                    </span>
                    <span className="d-block fs-4">$875</span>
                  </th>
                  <th scope="col">
                    <span
                      className="text-uppercase text-dark"
                      style={{ fontSize: "12px" }}
                    >
                      Marquise
                    </span>
                    <span className="d-block fs-4">$950</span>
                  </th>
                  <th scope="col">
                    <span
                      className="text-uppercase text-dark"
                      style={{ fontSize: "12px" }}
                    >
                      Princess
                    </span>
                    <span className="d-block fs-4">$1275</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    Seating for 60 people & Entry Gift/Dessert Table
                  </th>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <th scope="row">Table Covers</th>
                  <td></td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <th scope="row">Table Runners</th>
                  <td></td>
                  <td></td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <th scope="row">Table Centerpieces</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <th scope="row">Entry Table Centerpiece</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <th scope="row">Matching Golden Arches</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>✓</td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <th scope="row">4 Matching Outdoor Cocktail Tables</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>✓</td>
                  <td>✓</td>
                </tr>
                <tr>
                  <th scope="row">Balloon Backdrop</th>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>✓</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="decor-samples">
            <div className="container">
              <div className="row container py-3">
                <div className="col-none col-sm-6 my-auto p-0 p-lg-5 rounded">
                  <img
                    src={Decor}
                    alt="Baby shower balloon arch decoration at the Sixth & Main Event Center venue"
                    className="w-100 img-fluid d-none d-sm-block mx-auto rounded"
                    id="vision-illustration"
                  />
                </div>

                <div className="col col-sm-6 my-auto">
                  <h3 className="fs-1">
                    Designed to make your celebrations unforgettable
                  </h3>
                  <p className="mb-0">
                    Whether you're hosting a community event or celebrating a
                    personal milestone, our venue can be tailored to suit your
                    vision.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <hr />
            <div
              className="container py-4 mb-2"
              id="amenities-container"
            >
              <h3 className="text-start">Amenities</h3>
              <div className="container ms-3 px-0">
                <div
                  className="row w-100"
                  id="venue-amenities"
                >
                  <div className="col">
                    <p>
                      <FontAwesomeIcon
                        icon={icon({
                          name: "table-picnic",
                          family: "duotone",
                          style: "solid",
                        })}
                        className="me-2 text-start"
                      />
                      Tables
                    </p>
                    <p>
                      <FontAwesomeIcon
                        icon={icon({
                          name: "chair",
                          family: "duotone",
                          style: "solid",
                        })}
                        className="me-2"
                      />
                      Chairs
                    </p>
                    <p>
                      <FontAwesomeIcon
                        icon={icon({
                          name: "rug",
                          family: "duotone",
                          style: "solid",
                        })}
                        className="me-2"
                      />
                      Linens
                    </p>
                    <p>
                      <FontAwesomeIcon
                        icon={icon({
                          name: "wheelchair",
                          family: "duotone",
                          style: "solid",
                        })}
                        className="me-2"
                      />
                      Accessible
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <FontAwesomeIcon
                        icon={icon({
                          name: "restroom",
                          family: "duotone",
                          style: "solid",
                        })}
                        className="me-2"
                      />
                      Restroom
                    </p>
                    <p>
                      <FontAwesomeIcon
                        icon={icon({
                          name: "microwave",
                          family: "duotone",
                          style: "solid",
                        })}
                        className="me-2"
                      />
                      Kitchenette
                    </p>
                    <p>
                      <FontAwesomeIcon
                        icon={icon({
                          name: "refrigerator",
                          family: "duotone",
                          style: "solid",
                        })}
                        className="me-2"
                      />
                      Fridge
                    </p>
                    <p>
                      <FontAwesomeIcon
                        icon={icon({
                          name: "square-parking",
                          family: "duotone",
                          style: "solid",
                        })}
                        className="me-2"
                      />
                      Onsite Parking
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>

          <div
            className="about-container my-4"
            id="banner-about"
          >
            <p className="text-white text-center m-0 px-5 px-sm-4">
              Custom centerpieces made to awe
            </p>
          </div>

          <div className="container map-embed mb-3">
            <h3 className="fs-1">The location</h3>
            <p>
              Located on the corner of 6th Street & Main Avenue, our venue is
              easily accessible and surrounded by the charm & character of
              Robstown, TX.
            </p>

            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d727.19802050513!2d-97.66222709337386!3d27.78950786363994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86687db053a405f9%3A0x6afa62d440481b8d!2sSixth%20%26%20Main%20Event%20Center!5e0!3m2!1sen!2sus!4v1700076225668!5m2!1sen!2sus"
              width="300"
              height="300"
              allowFullScreen=""
              className="border-0 rounded w-100"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Map and Directions to Sixth & Main Event Center Venue"
              alt="Embedded map of the venue Sixth & Main Event Center in Robstown, Texas"
            ></iframe>
          </div>

          <div className="container">
            <a
              href="/reservation"
              className="btn btn-primary mt-3 d-block m-auto text-uppercase"
              role="button"
            >
              Book Now!
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
