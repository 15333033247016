import ImageCloudinary from "./ImageCloudinary";
import "./ImageGrid.css";

const ImageGrid = ({ imageIds, event }) => {
  // Function to chunk the array into smaller arrays of a specified size
  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  // Divide the imageIds into chunks of 3
  const columns = chunkArray(imageIds, 3);

  return (
    <div className="image-grid-container rounded">
      <div className="event-section">
        <h4 className="my-2 fw-medium text-center text-black">
          {event.type} - {event.theme}
        </h4>
        <div className="photo-gallery rounded">
          {columns.map((column, columnIndex) => (
            <div
              className="column"
              key={columnIndex}
            >
              {column.map((id, imageIndex) => (
                <div
                  className="h-100 w-100"
                  key={imageIndex}
                >
                  <ImageCloudinary publicId={id} />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageGrid;
