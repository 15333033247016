export const events = [
  {
    type: "Baby Shower",
    theme: "We Can Bearly Wait 🧸",
  },
  {
    type: "Graduation Party",
    theme: "Rosy Carnations 🌹",
  },
  {
    type: "Graduation Party",
    theme: "Golden Grad 🎓",
  },
  {
    type: "Graduation Party",
    theme: "Green & Gold 🎓",
  },
  {
    type: "Wedding",
    theme: "Lavender Love 🪻",
  },
];

export const imageIds = [
  // List each subarray with their corresponding public IDs here
  // baby-shower-bear
  [
    "event187_wg16sm",
    "event61_nkrucx",
    "event71_mobfhz",
    "event75_fgkkua",
    "event80_ibp8zx",
    "event190_pneamr",
  ],
  // graduation-maroon
  ["event110_szvapd", "event77_ggfqlc", "event112_qsbsbe", "event113_aaghjw"],
  // graduation-yellow-floral
  ["event85_doazsh", "event118_a3rpzm", "event119_jcuj2h", "event120_m8467c"],
  [
    "grad_green6",
    "grad_green1",
    "grad_green2",
    "grad_green12",
    "grad_green5",
    "grad_green4",
  ],
  // wedding-lavender
  [
    "wedding_lavender2",
    "wedding_lavender",
    "wedding_lavender4",
    "wedding_lavender1",
    "wedding_lavender5",
    "wedding_lavender3",
  ],
];
