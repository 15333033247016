import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Gallery from "./pages/gallery/Gallery";
import Contact from "./pages/contact/Contact";
import Tour from "./pages/tour/Tour";
import Reservation from "./pages/reservation/Reservation";
import { Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import { PopupButton } from "react-calendly";

function App() {
  return (
    <div
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      data-bs-title="Tooltip on top"
    >
      <PopupButton
        url="https://calendly.com/sixthandmain/venue-reservation?hide_gdpr_banner=1"
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        pageSettings={{ primaryColor: "dc143c" }}
        rootElement={document.getElementById("root")}
        text="BOOK NOW ⇱"
        styles={{
          height: "auto",
          position: "fixed",
          bottom: "10px",
          right: "15px",
          borderRadius: "20px",
          border: "none",
          backgroundColor: "red",
          color: "white",
          padding: "10px 20px",
          textTransform: "uppercase",
          zIndex: "30",
        }}
      />
      <Routes>
        <Route
          path="/"
          element={<Home />}
        />
        <Route
          path="/about"
          element={<About />}
        />
        <Route
          path="/gallery"
          element={<Gallery />}
        />
        <Route
          path="/contact"
          element={<Contact />}
        />
        <Route
          path="/tour"
          element={<Tour />}
        />
        <Route
          path="/reservation"
          element={<Reservation />}
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
