import Carousel from "react-bootstrap/Carousel";
import Venue from "../assets/sixth-and-main-venue.jpg";
import VenueEntry from "../assets/sixth-and-main-venue2.jpg";
import Lighting from "../assets/sixth-and-main-venue4.jpg";
import Seating from "../assets/sixth-and-main-venue5.jpg";
import Restroom from "../assets/sixth-and-main-venue7.jpg";
import Vanity from "../assets/sixth-and-main-venue8.jpg";
import StockRoom from "../assets/sixth-and-main-venue9.jpg";
import "./GalleryCarousel.css";

export default function GalleryCarousel() {
  return (
    <div className="GalleryCarousel">
      <Carousel
        id="carousel"
        className="w-75 d-block m-auto"
      >
        <Carousel.Item interval={10000}>
          <img
            src={Venue}
            alt="View of Sixth & Main Event Center round tables and chairs"
            className="img-fluid"
            id="landscape"
          />
          <Carousel.Caption
            className="d-block"
            id="landscape-caption"
          >
            <h3>Sixth & Main Event Center</h3>
            <p className="mb-1">Occupancy: 60 people</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={VenueEntry}
            className="img-fluid"
            alt="View of the Robstown, Texas venue, Sixth & Main Event Center, interior entry"
            id="landscape"
          />
          <Carousel.Caption id="landscape-caption">
            <h3>Foyer</h3>
            <p className="mb-1 px-1">
              Landing space to greet your guests with photos, sign-in sheet, or
              guestbook!
            </p>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={10000}>
          <img
            src={Lighting}
            alt="Lighting at the Robstown, Texas venue, Sixth & Main Event Center"
            className="img-fluid"
            id="landscape"
          />
          <Carousel.Caption id="landscape-caption">
            <h3>Lighting</h3>
            <p className="mb-1 px-1">
              Enjoy both natural lighting & artifical lighting supported by
              sharp fixtures.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={10000}>
          <img
            src={Seating}
            alt="View of the seating inside Robstown, Texas venue, Sixth & Main Event Center"
            className="img-fluid"
            id="landscape"
          />
          <Carousel.Caption id="landscape-caption">
            <h3>Seating</h3>
            <p className="mb-1">
              Tables, chairs, and linens available for use.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={10000}>
          <img
            src={Restroom}
            alt="View of the restroom facilities at Robstown, Texas venue, Sixth & Main Event Center"
            className="img-fluid"
            id="landscape"
          />
          <Carousel.Caption id="landscape-caption">
            <h3>Restroom</h3>
            <p className="mb-1">Stocked for your guests' comfort.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={10000}>
          <img
            src={Vanity}
            alt="View of restroom vanity inside the Robstown, Texas venue, Sixth & Main Event Center"
            className="img-fluid"
            id="portrait"
          />
          <Carousel.Caption
            id="portrait-caption"
            className="mx-5 mb-3"
          >
            <h3>Modern Fixtures</h3>
            <p>Spotless and sanitized.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={10000}>
          <img
            src={StockRoom}
            alt="Strockroom inside Robstown, Texas venue, Sixth & Main Event Center"
            className="img-fluid"
            id="portrait"
          />
          <Carousel.Caption
            id="portrait-caption"
            className="mx-5 mb-3"
          >
            <h3>Stock Room</h3>
            <p>Great for storing items needed for your event!</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
