import React, { useState } from "react";
import Logo from "../assets/logo-nav.svg";
import "./Navigation.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavbarToggle from "react-bootstrap/NavbarToggle";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

function Navigation() {
  const [colorChange, setColorchange] = useState(false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 10) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  const [background, setBackground] = useState("bg-none");

  function fillBackground() {
    if (background !== "bg-none") setBackground("bg-none");
    else setBackground("dropdown-background");
  }

  return (
    <div
      className="Navigation"
      role="navigation"
    >
      <Navbar
        expand="sm"
        className={colorChange ? "colorChange" : "navbar" + " " + background}
        fixed="top"
        id="navbar"
      >
        <Container
          fluid
          expand="sm"
          className="navContainer"
        >
          <Navbar.Brand
            href="/"
            className="me-0"
          >
            <img
              src={Logo}
              alt="Sixth & Main Event Center Logo"
              width="120"
              className="my-auto ms-md-3"
              id="nav-brand-icon"
            />
          </Navbar.Brand>

          <NavbarToggle
            aria-controls={`offcanvasNavbar-expand-sm`}
            id="toggle"
            onClick={fillBackground}
          />

          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-sm`}
            aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
            placement="end"
            className="bg-dark text-light"
          >
            <Offcanvas.Header
              closeButton
              className="bg-dark text-white d-flex d-sm-none justify-content-end fs-2 me-2 mt-2 mb-0 pb-0"
            ></Offcanvas.Header>
            <Offcanvas.Body className="d-flex">
              <Nav className="justify-content-center align-items-center flex-grow-1 px-sm-0">
                <Nav.Link
                  href="/"
                  id="home-link"
                  className="px-1 px-md-2"
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  href="/about"
                  id="about-link"
                  className="px-1 px-md-2"
                >
                  About
                </Nav.Link>
                <Nav.Link
                  href="/gallery"
                  id="gallery-link"
                  className="px-1 px-md-2"
                >
                  Gallery
                </Nav.Link>
                <Nav.Link
                  href="/contact"
                  id="contact-link"
                  className="px-1 px-md-2"
                >
                  Contact
                </Nav.Link>
                <Nav.Link
                  href="/tour"
                  id="tour-link"
                  className="px-1 px-md-2"
                >
                  Schedule Tour
                </Nav.Link>
                <Nav.Link
                  href="/reservation"
                  id="reservation-link"
                  className="rounded d-block d-sm-none px-1 px-md-2"
                >
                  Reserve Now
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>

          <Nav.Link
            href="/reservation"
            id="reservation-link"
            className="rounded d-none d-sm-block p-2 px-md-2"
          >
            <FontAwesomeIcon
              icon={icon({
                name: "calendar-days",
                family: "duotone",
                style: "solid",
              })}
              className="mx-1"
            />
            Reserve Now
          </Nav.Link>
        </Container>
      </Navbar>
    </div>
  );
}

export default Navigation;
